import React from 'react';
import PatientContextProvider from '../../context/PatientContext';
import PatientSelfSignUpWrapper from '../PatientSelfSignUpWrapper/PatientSelfSignUpWrapper';

const PatientSelfSignUp = () => {
	return (
		<PatientContextProvider>
			<PatientSelfSignUpWrapper />
		</PatientContextProvider>
	);
};

export default PatientSelfSignUp;
