import React from 'react';
import { Navigate } from 'react-router-dom';

const ProtectedRoute = ({ children, authProviderMethod, defaultRoute }) => {
	const isAuthenticated = authProviderMethod();
	if (!isAuthenticated) {
		return <Navigate to={defaultRoute} replace />;
	}
	return children;
};

export default ProtectedRoute;
