import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import React from 'react';
import { Row, Col } from 'antd';

const LoaderPage = () => {
	return (
		<Row
			style={{ height: '100vh', width: '100%' }}
			justify="center"
			align="middle"
		>
			<Spin indicator={<LoadingOutlined />} tip="Loading" />
		</Row>
	);
};

export default LoaderPage;
