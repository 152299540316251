import React from 'react';
import { Result } from 'antd';
import { useSearchParams } from 'react-router-dom';
import { stripeSuccess } from '../../api/stripe';

import './Checkout.css';

const CheckoutSuccess = () => {
	const [URLSearchParams] = useSearchParams();

	const onStripeSuccess = async () => {
		try {
			const checkout_session = URLSearchParams.get('session_id');
			await stripeSuccess({ checkout_session });
		} catch (err) {
			console.log(err);
		}
	};
	onStripeSuccess();

	return (
		<div className="checkout-main-div">
			<Result
				status="success"
				title="Successfully Subscribed to Ucardia!"
				subTitle="You can now use all the features of Ucardia App!"
			/>
		</div>
	);
};

export default CheckoutSuccess;
