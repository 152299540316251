import React from 'react';

import { Col, DatePicker, Form, Input, Row, Select, Divider } from 'antd';
import './PatientSelfSignUpDemographicInfo.css';

const { Option } = Select;

const Feet = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
const Inches = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11];

const PatientSelfSignUpDemographicInfo = ({ form, onSubmit }) => {
	const handleFinish = (data) => {
		const patientDemographicData = {
			...data,
		};
		onSubmit(patientDemographicData);
	};

	return (
		<Form
			style={{ backgroundColor: 'white' }}
			layout="vertical"
			form={form}
			onFinish={handleFinish}
		>
			<Row>
				<Col span={24}>
					<p className="demographic-heading">Demographic Information</p>
					<Divider style={{ marginTop: '0px' }} />
				</Col>
			</Row>

			<Row gutter={16} style={{ margin: '0px 15px 0px 15px' }}>
				<Col span={8}>
					<Form.Item
						label="First Name"
						name="firstName"
						rules={[
							{
								required: true,
							},
						]}
					>
						<Input placeholder="First Name" />
					</Form.Item>
				</Col>

				<Col span={8}>
					<Form.Item
						label="Last Name"
						name="lastName"
						rules={[
							{
								required: true,
							},
						]}
					>
						<Input placeholder="Last Name" />
					</Form.Item>
				</Col>

				<Col span={8}>
					<Form.Item
						label="Date of Birth"
						name="dateOfBirth"
						rules={[
							{
								required: true,
							},
						]}
					>
						<DatePicker placeholder="MM/DD/YYYY" style={{ width: '100%' }} />
					</Form.Item>
				</Col>
			</Row>

			<Row gutter={16} style={{ margin: '0px 15px 0px 15px' }}>
				<Col span={8}>
					<Form.Item label="Sex" name="sex">
						<Select placeholder="Select">
							<Option value="Male">Male</Option>
							<Option value="Female">Female</Option>
							<Option value="Other">Other</Option>
						</Select>
					</Form.Item>
				</Col>

				<Col span={8}>
					<Input.Group>
						<Row>
							<Col span={12}>
								<Form.Item label="Height" name="feet">
									<Select placeholder="Feet">
										{Feet.map((val, index) => (
											<Option key={index} value={val}>
												{val}
											</Option>
										))}
									</Select>
								</Form.Item>
							</Col>
							<Col span={12}>
								<Form.Item name="inches" style={{ marginTop: '30px' }}>
									<Select placeholder="Inches">
										{Inches.map((val, index) => (
											<Option key={index} value={val}>
												{val}
											</Option>
										))}
									</Select>
								</Form.Item>
							</Col>
						</Row>
					</Input.Group>
				</Col>

				<Col span={8}>
					<Form.Item
						label="Weight"
						name="weight"
						rules={[
							{
								required: true,
								max: 3,
							},
						]}
					>
						<Input placeholder="LBS" />
					</Form.Item>
				</Col>
			</Row>

			<Row gutter={40}>
				<Col span={8} offset={8}>
					<Form.Item label="Primary Language" name="primaryLanguage">
						<Select placeholder="Select">
							<Option value="English">English</Option>
							<Option value="Spanish">Spanish</Option>
						</Select>
					</Form.Item>
				</Col>
			</Row>
		</Form>
	);
};

export default PatientSelfSignUpDemographicInfo;
