export const zipcodePattern =
	/^([1-9]\d{4}|0[1-9]\d{3}|00[1-9]\d{2}|000[1-9]\d|0000[1-9])(-([1-9]\d{3}|0[1-9]\d{2}|00[1-9]\d|000[1-9]))?$/;
export const phonePattern =
	/^([+][ ]?[1])?[ ]?\(?(\d{3})\)?[- ]?(\d{3})[- ]?(\d{4})( x\d{4})?$/;

export const emailPattern =
	/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export const specialCharsRegex = /[\(\)\@\\\/\^\#\!\`\~\%\&\*\[\]\{\}\+\=\?]/;

export const USER_COOKIE_NAME = 'u';
export const TOKEN_COOKIE_NAME = 'access_token';
