import React from 'react';
import './PatientSelfSignUpContactInfo.css';
import { Col, Form, Input, Row, Select, Divider } from 'antd';
import { verifyEmailInput } from '../../api/user';
import { phonePattern, emailPattern } from '../../utils/constants';
import US_STATES from '../../utils/US-States';

const { Option } = Select;

const PatientSelfSignUpContactInfo = ({ form, onSubmit }) => {
	const handleFinish1 = (data) => {
		const contactInfo = {
			...data,
		};
		onSubmit(contactInfo);
	};

	return (
		<Form
			style={{ backgroundColor: 'white' }}
			layout="vertical"
			form={form}
			onFinish={handleFinish1}
		>
			<Row>
				<Col span={24}>
					<p className="contact-heading">Contact Information</p>
					<Divider style={{ marginTop: '0px' }} />
				</Col>
			</Row>

			<Row gutter={16} style={{ margin: '0px 15px 0px 15px' }}>
				<Col span={12}>
					<Form.Item
						label="Phone Number"
						name="phoneNumber"
						rules={[
							{
								required: true,
							},
							{
								pattern: phonePattern,
								message: 'Please input a valid Phone Number!',
							},
						]}
					>
						<Input placeholder="555-555-555" />
					</Form.Item>
				</Col>

				<Col span={12}>
					<Form.Item
						label="Email Address"
						name="email"
						rules={[
							{
								required: true,
								pattern: emailPattern,
								message: 'Please input a valid Email Address!',
							},
							{
								validator: async (_, value) => {
									try {
										if (!emailPattern.test(value)) return Promise.resolve();
										const isValid = await verifyEmailInput(value);
										if (isValid) return Promise.resolve();
										return Promise.reject(
											new Error('User with this email already exists!'),
										);
									} catch (err) {
										console.log('///Error--', err);
									}
								},
							},
						]}
					>
						<Input placeholder="example@email.com" />
					</Form.Item>
				</Col>
			</Row>

			<Row gutter={16} style={{ margin: '0px 15px 0px 15px' }}>
				<Col span={12}>
					<Form.Item
						label="Shipping Address"
						name="shippingAddress"
						rules={[
							{
								required: true,
							},
						]}
					>
						<Input placeholder="1234 Example Street" />
					</Form.Item>
				</Col>

				<Col span={12}>
					<Form.Item
						label="Shipping Address Line 2"
						name="shippingAddressLineTwo"
					>
						<Input placeholder="Optional" />
					</Form.Item>
				</Col>
			</Row>

			<Row gutter={16} style={{ margin: '0px 15px 0px 15px' }}>
				<Col span={8}>
					<Form.Item
						label="City"
						name="city"
						rules={[
							{
								required: true,
							},
						]}
					>
						<Input placeholder="City" />
					</Form.Item>
				</Col>

				<Col span={8}>
					<Form.Item
						label="State"
						name="state"
						rules={[
							{
								required: true,
							},
						]}
					>
						<Select placeholder="Select State" showSearch>
							{US_STATES.map((state, index) => (
								<Option key={index} value={state}>
									{state}
								</Option>
							))}
						</Select>
					</Form.Item>
				</Col>

				<Col span={8}>
					<Form.Item label="Zip" name="zip">
						<Input placeholder="Zip Code" />
					</Form.Item>
				</Col>
			</Row>
		</Form>
	);
};

export default PatientSelfSignUpContactInfo;
