import React, { useRef, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { Col, Row, Button } from 'antd';
import { PatientContext } from '../../context/PatientContext';
import ucardiaLogo from '../../assets/imgs/UCARDIA-LOGO.png';
import PatientSelfSignUpDemographicInfo from '../PatientSelfSignUpDemographicInfo/PatientSelfSignUpDemographicInfo';
import PatientSelfSignUpContactInfo from '../PatientSelfSignUpContactInfo/PatientSelfSignUpContactInfo';
import './PatientSelfSignUpForm.css';
import ReCAPTCHA from 'react-google-recaptcha';
import { Form, notification } from 'antd';
import {
	createPatientSelfSignUp,
	verifyCaptcha,
} from '../../api/patientSelfSignUp';

const PatientSelfSignUpForm = () => {
	const patientContext = useContext(PatientContext);

	const [patientSelfSignUpForm] = Form.useForm();

	const navigate = useNavigate();
	// const captchaRef = useRef(null);

	const onEnroll = () => {
		patientSelfSignUpForm.submit();
	};

	// COMMENTING RECAPTCHA RELATED CODE DUE TO DNS ISSUE
	// const captchaVerification = async () => {
	// 	const token = captchaRef.current.getValue();
	// 	captchaRef.current.reset();

	// 	try {
	// 		await verifyCaptcha(token);
	// 	} catch (err) {
	// 		notification.error({
	// 			message: 'Captcha Verification Error!',
	// 			duration: 3,
	// 		});
	// 	}
	// };

	const onPatientSelfSignUpFormSubmit = async (data) => {
		const patientData = {
			...data,
			dateOfBirth: data.dateOfBirth.format('MM/DD/YYYY'),
		};

		try {
			patientContext.setEmail(patientData.email);
			// await captchaVerification();
			await createPatientSelfSignUp(patientData);
			navigate('/patient/thankYou');
		} catch (err) {
			notification.error({
				message: 'Patient Enrollment Error!',
				description: 'Some unknown error occurred.',
				duration: 3,
			});
		}
	};

	return (
		<Row style={{ backgroundColor: '#F0F2F5', height: '100%' }}>
			<Col span={20} offset={2}>
				<Row>
					<Col span={4} offset={10}>
						<img src={ucardiaLogo} />
					</Col>
				</Row>

				<Row>
					<Col span={6} offset={9} className="enrollment-form">
						<p>Patient Enrollment Form</p>
					</Col>
				</Row>

				<Row>
					<Col span={24} style={{ textAlign: 'center' }}>
						<p>
							Signing up for personalized cardiac rehab is simple! Fill out the
							form below, add your payment information, and your exercise
							physiologist will reach
						</p>
						<p>
							out to you to get started. We'll send you an at-home rehab kit so
							you can begin your heart recovery journey!
						</p>
					</Col>
				</Row>

				<PatientSelfSignUpDemographicInfo
					form={patientSelfSignUpForm}
					onSubmit={onPatientSelfSignUpFormSubmit}
				/>
				<br />
				<PatientSelfSignUpContactInfo
					form={patientSelfSignUpForm}
					onSubmit={onPatientSelfSignUpFormSubmit}
				/>
				<br />
				{/* <Row>
					<Col span={5} offset={10}>
						<ReCAPTCHA
							sitekey="6LeiJUYiAAAAAIHYcA0dLmMQz7BZGeQjwIsokqvH"
							ref={captchaRef}
						/>
					</Col>
				</Row>
				<br /> */}
				<Row>
					<Col span={2} offset={11}>
						<Button type="primary" shape="round" size="large" danger>
							<p className="enroll-btn-style" onClick={onEnroll}>
								Enroll
							</p>
						</Button>
					</Col>
				</Row>
			</Col>
		</Row>
	);
};

export default PatientSelfSignUpForm;
