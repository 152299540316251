import React, { useState } from 'react';

export const PatientContext = React.createContext({
	email: null,
	setEmail: () => {},
});

const PatientContextProvider = ({ children }) => {
	const [email, setEmail] = useState(null);

	return (
		<PatientContext.Provider value={{ email, setEmail }}>
			{children}
		</PatientContext.Provider>
	);
};

export default PatientContextProvider;
