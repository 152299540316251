import React from 'react';
import { Route, Routes } from 'react-router-dom';
import CheckoutSuccess from '../CheckoutSuccess/CheckoutSuccess';
import CheckoutFailed from '../CheckoutFailed/CheckoutFailed';
import PatientSelfSignUpForm from '../PatientSelfSignUpForm/PatientSelfSignUpForm';
import PatientSelfSignUpThankYou from '../PatientSelfSignUpThankYou/PatientSelfSignUpThankYou';

const PatientSelfSignUpWrapper = () => {
	return (
		<Routes>
			<Route path="/SignUp" element={<PatientSelfSignUpForm />} />
			<Route path="/ThankYou" element={<PatientSelfSignUpThankYou />} />
			<Route path="/stripePaymentSuccess" element={<CheckoutSuccess />} />
			<Route path="/stripePaymentFailed" element={<CheckoutFailed />} />
		</Routes>
	);
};

export default PatientSelfSignUpWrapper;
