import axios from 'axios';
import { PATIENT_SELF_SIGN_UP, VERIFY_CAPTCHA } from './endpoints';

export const createPatientSelfSignUp = async (patientData) => {
	try {
		await axios.post(PATIENT_SELF_SIGN_UP, patientData);
	} catch (err) {
		throw new Error('Error in storing patient data');
	}
};

export const verifyCaptcha = async (token) => {
	try {
		await axios.post(VERIFY_CAPTCHA, token);
	} catch (err) {
		throw new Error('Error in verifying CAPTCHA!');
	}
};
