import React from 'react';
import { Result } from 'antd';

import '../CheckoutSuccess/Checkout.css';

const CheckoutFailed = () => {
	return (
		<div className="checkout-main-div">
			<Result
				status="error"
				title="Subscription Failed for Ucardia!"
				subTitle="You have to purchase subscription in order to use all the features of Ucardia App!"
			/>
		</div>
	);
};

export default CheckoutFailed;
