import React, { useContext } from 'react';
import { Col, Row, Button, notification } from 'antd';
import ucardiaLogo from '../../assets/imgs/UCARDIA-LOGO.png';
import { PatientContext } from '../../context/PatientContext';
import { createCheckoutSession } from '../../api/stripe';

import './PatientSelfSignUpThankYou.css';

const PatientSelfSignUpThankYou = () => {
	const patientContext = useContext(PatientContext);

	const handleSubmit = async () => {
		try {
			const apiResponse = await createCheckoutSession({
				email_address: patientContext.email,
			});
			if (apiResponse?.data?.stripeSessionUrl) {
				window.location.href = apiResponse.data.stripeSessionUrl;
			} else {
				notification.error({
					message: 'User error!',
					description: 'User does not exist',
					duration: 2,
				});
			}
		} catch (err) {
			notification.error({
				message: 'Something went wrong!',
				description: 'Try paying from Ucardia mobile app',
				duration: 2,
			});
		}
	};

	return (
		<Row className="main-row">
			<Col span={14} offset={5}>
				<div>
					<Row>
						<Col span={6} offset={9}>
							<img src={ucardiaLogo} />
						</Col>
					</Row>

					<Row style={{ marginTop: '180px' }}>
						<Col span={12} offset={6}>
							<p className="thank-you">Thank you for enrolling!</p>
						</Col>
					</Row>
					<Row>
						<Col span={16} offset={4}>
							<p className="thank-you-decription">
								You should be receiving a confirmation email and a <br /> phone
								call from your dedicated exercise physiologist. <br /> Please
								continue your enrollment process by clicking <br /> Add Payment
								Information below.
							</p>
						</Col>
					</Row>
					<Row>
						<Col span={8} offset={8} style={{ textAlign: 'center' }}>
							<Button
								shape="round"
								danger
								className="payment-btn"
								onClick={handleSubmit}
							>
								<p style={{ fontSize: '18px', fontFamily: 'Montserrat' }}>
									Add Payment Information
								</p>
							</Button>
						</Col>
					</Row>

					<Row style={{ marginTop: '300px' }}>
						<Col span={14} offset={5} style={{ textAlign: 'center' }}>
							<p className="ucardia-support">
								If you have any questions or issues please contact us at
								support@ucardia.com
							</p>
							<br />
							<p className="ucardia-copyright">Copyright ©2022 Ucardia Inc.</p>
						</Col>
					</Row>
				</div>
			</Col>
		</Row>
	);
};

export default PatientSelfSignUpThankYou;
