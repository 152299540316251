import axios from 'axios';
import { CREATE_CHECKOUT_SESSION, STRIPE_SUCCESS } from './endpoints';

export const createCheckoutSession = async (email_address) => {
	try {
		const response = await axios.post(CREATE_CHECKOUT_SESSION, email_address);
		return response;
	} catch (err) {
		return new Error('Error in creating Checkout Session!');
	}
};

export const stripeSuccess = async (checkout_session) => {
	try {
		console.log(checkout_session);
		const success = await axios.get(STRIPE_SUCCESS, {
			params: checkout_session,
		});
		return success;
	} catch (err) {
		console.log(err);
		throw new Error('Something went wrong!');
	}
};
