import axios from 'axios';
import { PROFILE_PIC_URL, PROVIDER_LOGIN } from './endpoints';

export const providerLogin = async ({ username, password }) => {
	try {
		const response = await axios.post(PROVIDER_LOGIN, {
			emailAddress: username,
			password,
		});
		return response.data.data;
	} catch (err) {
		throw new Error(err.response.data.message);
	}
};

export const getProfilePictureURL = async (picture, authToken) => {
	try {
		const response = await axios.get(PROFILE_PIC_URL, {
			params: {
				picture,
			},
			headers: {
				Authorization: authToken,
			},
		});
		return response.data.data;
	} catch (err) {
		return null;
	}
};
