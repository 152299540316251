import axios from 'axios';
import { VERIFY_EMAIL_INPUT } from './endpoints';

export const verifyEmailInput = async (email) => {
	try {
		const response = await axios.get(VERIFY_EMAIL_INPUT, {
			params: { email },
		});
		return response.data.data.isValid;
	} catch (err) {
		throw new Error('An unknown error orccured!');
	}
};
