import { SEND_NOTIFICATIONS_URL } from '../../config';

export const VERIFY_EMAIL_INPUT = '/user/verifyEmailInput';
export const PROVIDER_LOGIN = '/session/provider';
export const REGISTER_PATIENT = '/user/patient?skipCaretaker=true';
export const UPLOAD_RECORD = '/patientRecords';
export const PROFILE_PIC_URL = '/user/profilePicture';
export const EDUCATIONAL_DEGREES = '/educationalDegree';
export const SINGLE_PROVIDER_DATA = '/user/healthProvider/single';
export const CONFIRM_PROVIDER = '/user/healthProvider';
export const PATIENTS_IN_ORGANIZATION = '/user/patientsInOrganization';
export const SESSION_TYPES = '/sessionTypes';
export const HEALTH_PROVIDER = CONFIRM_PROVIDER;
export const APPOINTMENTS = '/appointments';
export const PROVIDER_APPOINTMENTS = '/appointments/provider';
export const ORGANIZATION_APPOINTMENTS = '/appointments/organization';
export const SEARCH_PATIENT = '/appointments/searchPatients';
export const GET_ALL_PATIENTS = '/appointments/getAllPatients';
export const DELETE_APPOINTMENT = '/appointments/deleteAppointment';
export const SEARCH_PROVIDERS_PATIENTS =
	'/appointments/searchPatientsAssignedToProvider';
export const SEARCH_ORGANIZATIONS_PATIENTS = '/appointments/searchPatientsAssignedToOrganization';
export const ADD_PATIENT = 'appointments/addPatients';
export const REMOVE_PATIENT = 'appointments/deletePatient';
export const SEND_NOTIFICATIONS = SEND_NOTIFICATIONS_URL;
export const PATIENT_SELF_SIGN_UP = '/user/patientSelfSignUp';
export const VERIFY_CAPTCHA = '/captcha/verifyCaptcha';
export const SEND_TWO_FA_CODE = '/twoFactor/twoFactorAuthenticate';
export const VERIFY_TWO_FA_CODE = '/twoFactor/verifyTwoFa';
export const CREATE_CHECKOUT_SESSION = '/stripe/createCheckoutSession';
export const STRIPE_SUCCESS = '/stripe/customerCheckoutSuccess';
export const GET_ALL_ORGANIZATIONS = '/organization'
