import React, { useState } from 'react';

export const AuthContext = React.createContext({
	isLoggedIn: false,
	authToken: null,
	user: null,
	profilePicture: null,
	setIsLoggedIn: (flag) => {},
	setAuthToken: (token) => {},
	setUser: (user) => {},
	setProfilePicture: (picture) => {},
});

const AuthProvider = ({ children }) => {
	const [isLoggedIn, setIsLoggedIn] = useState(false);
	const [authToken, setAuthToken] = useState(null);
	const [user, setUser] = useState(null);
	const [profilePicture, setProfilePicture] = useState(null);

	return (
		<AuthContext.Provider
			value={{
				isLoggedIn,
				authToken,
				user,
				profilePicture,
				setAuthToken,
				setIsLoggedIn,
				setUser,
				setProfilePicture,
			}}
		>
			{children}
		</AuthContext.Provider>
	);
};

export default AuthProvider;
