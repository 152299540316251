import React from 'react';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import AppV2 from './v2';
import PatientSelfSignUp from './v2/components/PatientSelfSignUp/PatientSelfSignUp';

function App() {
	return (
		<BrowserRouter>
			<Routes>
				<Route path="/*" element={<AppV2 />} />
				<Route path="*" element={<Navigate to="/" replace />} />
				<Route path="/patient/*" element={<PatientSelfSignUp />} />
			</Routes>
		</BrowserRouter>
	);
}

export default App;
