export const ROOT_ROUTE = '/';
export const LOGIN_ROUTE = '/auth/login';
export const CONFIRM_ROUTE = '/auth/confirm';
export const UCHAT_ROUTE = '/dashboard/uchat';
export const CREATE_PATIENT_ROUTE = '/dashboard/patient/create';
export const PATIENT_RECORDS_ROUTE = '/dashboard/patient/records';
export const LIVE_MONITORING_ROUTE = '/dashboard/uchat/live';
export const ONE_TO_ONE_SESSION_ROUTE = '/dashboard/uchat/one';
export const APPOINTMENTS_ROUTE = '/dashboard/uchat/appointments';
export const TWO_FA_ROUTE = '/auth/2FA';
