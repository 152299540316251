import React, { Suspense, useContext, useEffect, useState } from 'react';
import { Navigate, Route, Routes, useNavigate } from 'react-router-dom';
import { getProfilePictureURL } from './api/auth';
import { getCookie } from './api/cookies';
import './App.css';
import { AuthContext } from './context/AuthContext';
import { LOGIN_ROUTE, UCHAT_ROUTE } from './utils/absolute-routes';
import LoaderPage from './utils/components/LoaderPage/LoaderPage';
import ProtectedRoute from './utils/components/ProtectedRoute/ProtectedRoute';

const Auth = React.lazy(() => import('./pages/Auth/Auth'));
const Dashboard = React.lazy(() => import('./pages/Dashboard/Dashboard'));

const App = () => {
	const [contextLoaded, setContextLoaded] = useState(false);

	const authContext = useContext(AuthContext);
	const navigate = useNavigate();

	useEffect(() => {
		const loadContext = async () => {
			const user = getCookie('u');
			const token = getCookie('access_token');
			if (!!user && !!token) {
				try {
					if (user.picture !== null) {
						const pictureURL = await getProfilePictureURL(user.picture, token);
						authContext.setProfilePicture(pictureURL);
					}
					authContext.setUser(user);
					authContext.setAuthToken(token);
					authContext.setIsLoggedIn(true);
					setContextLoaded(true);
				} catch (err) {
					setContextLoaded(true);
					navigate(LOGIN_ROUTE, { replace: true });
				}
			} else {
				setContextLoaded(true);
				navigate(LOGIN_ROUTE, { replace: true });
			}
		};
		loadContext();
	}, []);

	return (
		<Routes>
			<Route
				path="auth//*"
				element={
					<Suspense fallback={<LoaderPage />}>
						<Auth />
					</Suspense>
				}
			/>
			{contextLoaded ? (
				<Route
					path="dashboard//*"
					element={
						<Suspense fallback={<LoaderPage />}>
							<ProtectedRoute
								authProviderMethod={() => authContext.isLoggedIn}
								defaultRoute={LOGIN_ROUTE}
							>
								<Dashboard />
							</ProtectedRoute>
						</Suspense>
					}
				/>
			) : (
				''
			)}
			{contextLoaded ? (
				<Route path="/*" element={<Navigate to={UCHAT_ROUTE} replace />} />
			) : (
				''
			)}
		</Routes>
	);
};

export default App;
