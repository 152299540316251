import axios from 'axios';
import React from 'react';
import { UCARDIA_API_BASE_URL } from '../config';
import App from './App';
import AuthProvider from './context/AuthContext';

axios.defaults.baseURL = UCARDIA_API_BASE_URL;

const AppV2 = () => {
	return (
		<AuthProvider>
			<App />
		</AuthProvider>
	);
};

export default AppV2;
